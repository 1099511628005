<template>
  <div class="header-container">
    <div class="title-wrapper">
      <span class="subtitle">Clumsy Valley</span>
      <h1 class="title">
        Calculator Tool
      </h1>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SiteHeader'
}
</script>

<style scoped lang="scss">
.header-container {
  margin-bottom: 50px;

  .logo {
    height: 30px;
    width: auto;

    @media only screen and (min-width: 800px) {
      height: 50px;
    }
  }
}

.title-wrapper {
  .subtitle {
    font-family: "Pixel Tiny", serif;
    color: #ffffff;
    font-size: 50px;

    @media only screen and (min-width: 800px) {
      font-size: 70px;
    }
  }

  .title {
    color: #ffffff;
    font-size: 30px;
    font-weight: 700;

    @media only screen and (min-width: 800px) {
      font-size: 60px;
    }
  }
}
</style>
