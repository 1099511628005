<template>
  <div class="loot-table-item">
    <div class="item-name">
      {{ name }}
    </div>
    <div class="item-chance">
      <!--      {{ chance }}%-->
      TBD
    </div>
  </div>
</template>

<script>
export default {
  name: 'LootTableItem',
  props: {
    name: {
      type: String,
      required: true
    },
    chance: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
.loot-table-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  &:first-child {
    .item-name,
    .item-chance {
      padding-top: 30px;
    }
  }

  &:last-child {
    .item-name,
    .item-chance {
      padding-bottom: 30px;
    }
  }

  .item-name {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px 30px;
    font-size: 16px;
    font-weight: 500;

    @media only screen and (min-width: 800px) {
      font-size: 20px;
    }
  }

  .item-chance {
    display: flex;
    align-items: center;
    border-left: $grey-border;
    font-size: 20px;
    font-weight: 200;
    width: 80px;
    padding: 20px 10px 20px 10px;

    @media only screen and (min-width: 746px) {
      padding: 20px 10px 20px 30px;
      width: 100px;
    }

    @media only screen and (min-width: 1000px) {
      width: 250px;
    }
  }
}
</style>
