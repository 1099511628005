<template>
  <div class="container">
    <SiteHeader/>

    <div class="grid content">
      <div class="col col-4 col-xl sidebar">
        <SiteSidebar
            @ghost-data="updateGhost"
        />
      </div>

      <div class="col col-8 col-xl main">
        <div class="stats-title" v-if="ghostData.modifiers">
          <h2>Modified Stats</h2>
        </div>

        <div class="modified-stats" v-if="ghostData.modifiers">
          <div class="modified-stat" v-if="ghostData.modifiers">
            <div class="stat-name">
              Farming Length (7 Days)
            </div>
            <div class="stat-value">
              {{ ghostData.modifiers.farming_length }}
            </div>
          </div>

          <div class="modified-stat" v-if="ghostData.modifiers">
            <div class="stat-name">
              Biome Modifier
            </div>
            <div class="stat-value">
              +{{ ghostData.modifiers.biome_modifier }}
            </div>
          </div>
        </div>

        <div class="attribute-stats" v-if="ghostData.stats">
          <div class="attribute-stat">
            <div class="stat-name">
              <img class="stat-icon" src="./assets/images/stamina-icon.png" alt="Stamina icon">
              Stamina
            </div>
            <div class="stat-value">
              {{ ghostData.stats.stamina }}
            </div>
          </div>

          <div class="attribute-stat">
            <div class="stat-name">
              <img class="stat-icon" src="./assets/images/smarts-icon.png" alt="Smarts icon">
              Smarts
            </div>
            <div class="stat-value">
              {{ ghostData.stats.smart }}
            </div>
          </div>

          <div class="attribute-stat">
            <div class="stat-name">
              <img class="stat-icon" src="./assets/images/luck-icon.png" alt="Luck icon">
              Luck
            </div>
            <div class="stat-value">
              {{ ghostData.stats.luck }}
            </div>
          </div>

          <div class="attribute-stat">
            <div class="stat-name">
              <img class="stat-icon" src="./assets/images/speed-icon.png" alt="Speed icon">
              Speed
            </div>
            <div class="stat-value">
              {{ ghostData.stats.speed }}
            </div>
          </div>
        </div>

        <div class="loot-table" v-if="ghostData.loot_table">
          <LootTableItem
              v-for="(chance, name, index) in ghostData.loot_table"
              :key="`${ name }-${ index }`"
              :name="name"
              :chance="chance"/>
        </div>
      </div>
    </div>

    <SiteFooter/>
  </div>
</template>

<script>
import SiteHeader from "@/components/SiteHeader";
import SiteFooter from "@/components/SiteFooter";
import SiteSidebar from "@/components/SiteSidebar";
import LootTableItem from "@/components/LootTableItem";

export default {
  name: 'App',
  components: {
    LootTableItem,
    SiteHeader,
    SiteFooter,
    SiteSidebar
  },
  data() {
    return {
      ghostData: {}
    }
  },
  mounted() {
    const savedGhostData = localStorage.getItem('ghost_data')

    if (savedGhostData) {
      this.ghostData = JSON.parse(savedGhostData)
    }
  },
  methods: {
    updateGhost(data) {
      localStorage.setItem('ghost_id', data.id)
      localStorage.setItem('ghost_image', data.file_image)
      localStorage.setItem('ghost_data', JSON.stringify(data))

      this.ghostData = data
    }
  }
}
</script>

<style lang="scss">
#app {
  padding-top: 80px;
  padding-bottom: 80px;
}

.content {
  display: flex;
  flex-direction: row;
}

.sidebar {
  border: $grey-border;
  padding: 30px;
}

.main {
  padding: 0;

  @media only screen and (min-width: 800px) {
    padding-left: 10px;
  }

  .stats-title {
    border: $grey-border;
    padding: 30px;
    margin-bottom: 15px;

    h2 {
      font-size: 35px;
      color: #ffffff;
      font-weight: 700;
    }
  }
}

.modified-stats,
.attribute-stats,
.loot-table {
  margin-bottom: 15px;
  border: $grey-border;
}

.attribute-stat,
.modified-stat {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  &:first-child {
    .stat-name,
    .stat-value {
      padding-top: 30px;
    }
  }

  &:last-child {
    .stat-name,
    .stat-value {
      padding-bottom: 30px;
    }
  }

  .stat-name {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px 30px;
    font-weight: 700;
    font-size: 16;

    @media only screen and (min-width: 800px) {
      font-size: 20px;
    }

    .stat-icon {
      margin-right: 20px;
      width: 50px;
      height: auto;
    }
  }

  .stat-value {
    display: flex;
    align-items: center;
    border-left: $grey-border;
    font-size: 20px;
    font-weight: 200;
    width: 80px;
    padding: 20px 10px 20px 10px;

    @media only screen and (min-width: 746px) {
      padding: 20px 10px 20px 30px;
      width: 100px;
    }

    @media only screen and (min-width: 1000px) {
      width: 250px;
    }
  }
}
</style>
