<template>
  <div class="footer-container">
    <div class="footer-left">
      <div class="footer-socials">
        <a class="footer-social-link" href="https://twitter.com/Clumsy_Ghosts" title="Follow us on Twitter" target="_blank">
          <img src="../assets/images/twitter.png" alt="Twitter">
        </a>
        <a class="footer-social-link" href="https://discord.gg/qGEbb82J64" title="Join our Discord" target="_blank">
          <img src="../assets/images/discord.png" alt="Discord">
        </a>
      </div>
      <div class="footer-built-on">
        <a href="https://cardano.org/" title="Built on Cardano" target="_blank">
          <img src="../assets/images/built-on.png" alt="Built on Cardano">
        </a>
      </div>
    </div>
    <div class="footer-right">
      <p>Stats are not accurate and subject to change.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SiteFooter'
}
</script>

<style scoped lang="scss">
.footer-container {
  display: block;
  padding-top: 50px;

  @media only screen and (min-width: 800px) {
    padding-top: 130px;
    display: flex;
    justify-content: space-between;
  }
}

.footer-socials {
  .footer-social-link {
    padding: 10px 20px;

    &:first-of-type {
      margin-left: -20px;
    }
  }

  img {
    height: 20px;
    width: auto;
  }
}

.footer-built-on {
  margin-top: 15px;

  img {
    height: 30px;
    width: auto;
  }
}

.footer-right {
  p {
    font-size: 14px;
  }
}
</style>
