<template>
  <form class="sidebar-form" @submit.prevent="calcGhost" v-on:change="calcGhost">
    <div class="sidebar-form-group">
      <select v-model="inputBiome">
        <option value="" selected>Select Biome...</option>
        <option value="meadows">Meadows</option>
        <option value="dunes">Dunes</option>
        <option value="tundra">Tundra</option>
        <option value="scorched-earth">Scorched Earth</option>
        <option value="toxic-swamps">Toxic Swamps</option>
        <option value="cyber-district">Cyber District</option>
        <option value="deadlands">Deadlands</option>
        <option value="twilight-forest">Twilight Forest</option>
        <option value="alien">Alien</option>
      </select>
    </div>

    <div class="sidebar-form-group" style="margin-bottom:50px;" v-if="inputBiome">
      <div class="selected-biome">
        <h3 class="biome-name">{{ selectedBiome }}</h3>
        <div class="biome-image-wrapper">
          <img class="biome-image" src="../assets/images/biomes/meadows.png" alt="" v-if="inputBiome === 'meadows'">
          <img class="biome-image" src="../assets/images/biomes/dunes.png" alt="" v-if="inputBiome === 'dunes'">
          <img class="biome-image" src="../assets/images/biomes/tundra.png" alt="" v-if="inputBiome === 'tundra'">
          <img class="biome-image" src="../assets/images/biomes/scorched-earth.png" alt="" v-if="inputBiome === 'scorched-earth'">
          <img class="biome-image" src="../assets/images/biomes/toxic-swamps.png" alt="" v-if="inputBiome === 'toxic-swamps'">
          <img class="biome-image" src="../assets/images/biomes/cyber-district.png" alt="" v-if="inputBiome === 'cyber-district'">
          <img class="biome-image" src="../assets/images/biomes/deadlands.png" alt="" v-if="inputBiome === 'deadlands'">
          <img class="biome-image" src="../assets/images/biomes/twilight-forest.png" alt="" v-if="inputBiome === 'twilight-forest'">
          <img class="biome-image" src="../assets/images/biomes/alien.png" alt="" v-if="inputBiome === 'alien'">
        </div>
      </div>
    </div>

    <div class="sidebar-form-group">
      <input type="number" min="1" max="10000" placeholder="Search Ghost..." v-model="inputGhost">
    </div>

    <button type="submit" hidden>Calculate Ghost Stats</button>

    <div class="sidebar-form-group" v-if="ghostIframeSrc">
      <div class="selected-ghost">
        <iframe :src="ghostIframeSrc" frameborder="0"></iframe>
      </div>
    </div>
  </form>
</template>

<script>
import axios from 'axios'

function inRange(x, min, max) {
  return ((x - min) * (x - max) <= 0);
}

export default {
  name: "SiteSidebar",
  data() {
    return {
      isLoading: false,
      inputBiome: '',
      inputGhost: '',
      ghostIframeSrc: ''
    }
  },
  mounted() {
    let savedBiome = localStorage.getItem('biome_name')
    let savedGhostID = localStorage.getItem('ghost_id')
    let savedGhostImage = localStorage.getItem('ghost_image')

    if (savedBiome) {
      this.inputBiome = savedBiome
    }

    if (savedGhostID) {
      this.inputGhost = savedGhostID
    }

    if (savedGhostImage) {
      this.ghostIframeSrc = savedGhostImage
    }
  },
  computed: {
    selectedBiome() {
      const biomeString = this.inputBiome

      return biomeString.replaceAll('-', ' ')
    }
  },
  methods: {
    async calcGhost() {
      // todo: Input validation
      const biomeValue = this.inputBiome.toString().trim()
      const ghostValue = this.inputGhost.toString().trim()

      // todo: Build better form validation
      // Since submit is on change, we need both values
      if (!biomeValue || !ghostValue) {
        return
      }

      if (biomeValue === '' ||
          ghostValue === '' ||
          !inRange(parseInt(ghostValue), 1, 10000)
      ) {
        alert("Incorrect Submission. \n\nPlease check the Biome and Ghost ID and try again.")
        return
      }

      if (biomeValue && ghostValue) {
        let ghostData;

        await axios.get(`https://api.clumsycalculator.com/api/v1/calculator?ghost=${ghostValue}&biome=${biomeValue}`)
            .then(function (response) {
              // handle success
              const data = response.data

              if (data.success && data.success === true) {
                if (data.ghost) {
                  ghostData = data.ghost
                }
              }
            })
            .catch(function (error) {
              // handle error
              console.log(error);
              alert('There has been an error calculating this ghost. Please try again later')
            })
            .then(function () {
              // always executed
            });

        if (ghostData) {
          localStorage.setItem('biome_name', biomeValue)

          this.ghostIframeSrc = ghostData.file_image

          this.$emit('ghostData', ghostData)
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.sidebar-form {
  display: flex;
  flex-direction: column;

  .sidebar-form-group {
    margin-bottom: 30px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.selected-biome {
  border: $grey-border;
  padding: 20px 30px 35px;
  text-align: center;

  .biome-name {
    font-family: "Pixel Tiny", serif;
    font-size: 60px;
    font-weight: 500;
    line-height: .5;
    margin: 0;
    padding: 0;
  }

  .biome-image-wrapper {
  }

  .biome-image {
    max-width: 100%;
    height: auto;
  }
}

.selected-ghost {
  width: 100%;
  height: auto;
}
</style>